import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import lang_he from 'element-plus/lib/locale/lang/he';
import 'element-plus/lib/theme-chalk/index.css';
import ElementPlus from 'element-plus';

import VueNotificationList from '@dafcoe/vue-notification'
import '@dafcoe/vue-notification/dist/vue-notification.css'

import {projectAuth} from './Firebase/config';

import {slide_pop_info} from './Scripts/Msgs';
import {handle_when_user_connect,init_visibility} from './DebugTools/Models/Presence/scripts/scripts'
import {init_profile,init_permission} from './Scripts/Auth'

projectAuth.onAuthStateChanged(async user => {
    try{
        if(user){
            store.state.user = {
                name: user.displayName,
                uid: user.uid,
                email: user.email,
                phone: user.phoneNumber,
                photo_url:user.photoURL
            };
    
            const res = await init_profile(user)
            if(res){
                await init_permission(user)
            }
    
            handle_when_user_connect()
            init_visibility()
    
            slide_pop_info("קיים משתמש מחובר למערכת.")
        }else{
            store.state.user = null
        }
    }catch(error){
        // error_page
        router.replace({ name: 'ErrorPage', params: {err_msg: error.message }})
    }
})
createApp(App)
.use(store)
.use(router)
.use(ElementPlus, {locale: lang_he})
.use(VueNotificationList)
.mount('#app')
