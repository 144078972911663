import { createRouter, createWebHistory } from 'vue-router'
import NotFound from '../views/NotFound.vue'
import Home from '../views/Home/Home.vue'
import OS from '../views/Home_LoggedIn/Main_OS.vue'
import store from '../store'

const sleep = async () => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(true)
    }, 2500);
  })
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: async (to, from, next) => {
      store.state.loading = true;
      await sleep();
      if(!store.state.user){
        next()
        return
      }else{
        next('OS')
        return
      }
    }
  },
  { 
    path: '/:pathMatch(.*)*', 
    component: NotFound 
  },
  { 
    path: '/error-page', 
    name:'ErrorPage',
    component: () => import('../views/ErrorPage.vue'),
    props:true
  },
  {
    path: '/OS',
    name: 'OS',
    component: OS,
    beforeEnter: async (to, from, next) => {
      store.state.loading = true;
      store.state.loading_text = 'טוען מערכת הפעלה'
      await sleep();
      if(!store.state.user){
        next('/')
        return
      }
      next();
    },
    children:[
      {
        path:'/OS/noorg',
        name: 'noorg',
        component: () => import('../views/Home_LoggedIn/OS/NoOrg.vue')
      },
      {
        path:'/OS/setupneworg',
        name: 'setupneworg',
        component: () => import('../views/Home_LoggedIn/OS/SetupNewOrg/UserProfile.vue')
      },
      {
        path:'/OS/setupneworg2',
        name: 'setupneworg2',
        component: () => import('../views/Home_LoggedIn/OS/SetupNewOrg/OrgProfile.vue')
      },
      {
        path:'/OS/setupneworg3',
        name: 'setupneworg3',
        component: () => import('../views/Home_LoggedIn/OS/SetupNewOrg/NewOrgSetup.vue')
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
