<template>
  <div ref="main_debug_tools" class="main-debug-tools">
      <component :is="state"  @open_menu="handle_open_menu" @close_menu="handle_close_menu"
       @option_clicked="handle_show_debug_option" />

      <component :is="model" @close_model="model=null" />
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { defineAsyncComponent } from '@vue/runtime-core'
export default {
    components:{
        Toggle : defineAsyncComponent(() => import('./Components/Toggle.vue')),
        Menu : defineAsyncComponent(() => import('./Components/Menu.vue')),
        StoreViewer : defineAsyncComponent(() => import('./Models/StoreViewer.vue')),
        ComponentViewer : defineAsyncComponent(() => import('./Models/ComponentViewer.vue')),
        PresenceMain : defineAsyncComponent(() => import('./Models/Presence/PresenceMain.vue')),
    },
    setup(){
        const state = ref('Toggle')
        const model = ref(null)
        const main_debug_tools = ref()

        const handle_show_debug_option = (option)=>{
            handle_close_menu()
            model.value = option
        }

        const handle_open_menu = () => {
            main_debug_tools.value.classList.toggle('clicked')
            state.value = null
            setTimeout(() => {
              state.value = 'Menu'  
            }, 200);
            
        }

        const handle_close_menu = () => {
            main_debug_tools.value.classList.toggle('clicked')
            state.value = 'null'
            setTimeout(() => {
              state.value = 'Toggle'  
            }, 200);
          
        }
        
        return{
            handle_open_menu,
            handle_close_menu,
            handle_show_debug_option,
            state,
            model,
            main_debug_tools,
        }
    }
}
</script>

<style scoped>
    .main-debug-tools{
        position: fixed;
        bottom: 0;
        left: 10px;
        width: 200px;
        height: 50px;
        background: rgba(0, 0, 0, 0.4);
        z-index: 20000000;
        border-radius: 10px 10px 0 0;
        transition: height 0.2s ease-in;
        z-index: 999;
    }
    .main-debug-tools.clicked{
        height:50%;
    }
</style>