<template>
  <div v-loading="is_pending" class="chat-screen slide-in-left">
    <div class="chat-screen-header">
        <h4 dir="rtl">
                מתקיימת שיחה עם
                {{selected_user.displayName}}
        </h4>
    </div>
    <div class="chat-screen-tools">
        <span @click="$emit('back')" class="material-symbols-rounded icon">forward</span>
    </div>
    <div ref="chat_screen_msg" class="chat-screen-msgs">
        <template v-for="(msg,index) in sort_messages" :key="msg.msgId">
            <div v-if="index==0" class="messages-date">
                <p>{{display_date_from_iso(msg.createdAt)}}</p>
            </div>
            <div v-if="index>0 && display_date_from_iso(sort_messages[index-1].createdAt) != display_date_from_iso(msg.createdAt)" class="messages-date">
                <p>{{display_date_from_iso(msg.createdAt)}}</p>
            </div>

            <div v-if="msg.name==user.name" class="chat-line-service">
                <p style="margin-left:10px; font-size:0.7rem;">{{display_only_time_from_iso(msg.createdAt)}}</p>
                <!-- <p style="font-weight:bold; margin-left:10px;">{{msg.name}}</p> -->
                <p>{{msg.text_msg}}</p>
                <span style="margin: 0 5px;" v-if="msg.read" class="material-symbols-rounded icon-done">done_all</span>
                <span style="margin: 0 5px;" v-if="!msg.read" class="material-symbols-rounded icon-check">check</span>
            </div>
            <div dir="rtl" v-else class="chat-line-client">
                <p style="margin-left:10px; font-size:0.7rem;">{{display_only_time_from_iso(msg.createdAt)}}</p>
                <p v-if="msg.text_msg">{{msg.text_msg}}</p>
                <img class="snap-shot-img" v-if="msg.image" :src="msg.image">
                <a class="open-image" v-if="msg.image" :href="msg.image" target="_blank"></a>
            </div>
        </template> 
    </div>
    <div class="chat-screen-input">
        <el-input v-model="msg_text" @keypress.enter="handle_msg_text" style="width:75%;" :input-style="{'width': '100%', 'height': '100%', 'font-size': '20px'}" />
        <el-button  @click="handle_msg_text" style="width:23%; height:100%; margin:0; flex-shrink: 0;" type="success">שלח</el-button>
    </div>
  </div>
</template>

<script>
import {real_time_chat_messages,send_msg} from './Scripts/scripts'
import {display_date_from_iso,get_object_date_with_time,display_only_time_from_iso} from '../../Scripts/dates'
import {user} from '../../Scripts/Utils'
import { ref } from '@vue/reactivity'
import { computed, onBeforeUnmount, onUpdated } from '@vue/runtime-core'
export default {
    emits:['back'],
    props:['selected_user'],
    setup(props,context){
        const {primary_user,second_user,session_unsub,messages} = real_time_chat_messages(props.selected_user.uid)
        const chat_screen_msg = ref()
        const msg_text = ref('')
        const is_pending = ref(false)
        const handle_msg_text = async()=>{
            await send_msg(primary_user.value,second_user.value,msg_text.value)
            msg_text.value = ''
        }

        const sort_messages = ref(computed(()=>{
            if(messages.value.length){
                return messages.value.sort((a,b)=>{
                    return get_object_date_with_time(a.createdAt) - get_object_date_with_time(b.createdAt)
                })
            }
        }))

        onUpdated(()=>{
            if(chat_screen_msg.value){
                chat_screen_msg.value.scrollTop = chat_screen_msg.value.scrollHeight;
            }
        })
        onBeforeUnmount(()=>{
            if(session_unsub.value){
                session_unsub.value()
            }
        })

        return{
            handle_msg_text,
            msg_text,
            is_pending,
            chat_screen_msg,
            primary_user,
            second_user,
            messages,
            sort_messages,
            display_date_from_iso,
            user,
            display_only_time_from_iso
        }
    }
}
</script>

<style scoped>
    .chat-screen{
        width: 100%;
        height: 100%;
    }
    .chat-screen-header{
        width:100%;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--grad_bg);
        color: #fff;
    }
    .chat-screen-tools{
        width: 100%;
        height: 30px;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        padding: 0 5px;
        box-shadow: 0 2px 3px -2px gray;
    }
    .icon{
        background: var(--turkiz);
        border-radius: 50%;
        color: #fff;
        cursor: pointer;
    }
    .icon-done,.icon-check{
        font-size: 14px;
        color: var(--success);
        align-self: flex-end;
    }
    .icon-check{
       color: var(--dark_bg); 
    }
    .end-session-btn{
        border: 0;
        height: 80%;
        border-radius: 5px;
        padding: 0 5px;
        margin-right: auto;
        cursor: pointer;
    }
    .chat-screen-msgs{
        position: relative;
        width: 100%;
        height: calc(100% - 140px);
        overflow:hidden;
        overflow-y: auto;
        padding: 3px;
        display: flex;
        flex-direction: column;
    }
    .chat-screen-input,.chat-screen-ended-message{
        padding: 5px;
        position: relative;
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: space-between;
        flex-shrink: 0;
    }
    .chat-screen-input{
        flex-direction: row-reverse;
    }

    .chat-line-service,.chat-line-client,.messages-date{
        position: relative;
        width: fit-content;
        padding: 5px;
        background-color: #dcf8c6;
        display: flex;
        margin-bottom: 10px;
        flex-wrap: wrap;
        overflow: hidden;
        align-items: center;
        border-radius: 10px;
        flex-shrink: 0;
    }
    .chat-line-service{
        align-self: flex-end;
        direction: rtl;
    }
    .chat-line-client{
        background-color: rgb(223, 221, 221);
    }
    .chat-screen-ended-message{
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .messages-date{
        width: 100%;
        color: rgb(192, 189, 189);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1px;
        background-color: unset;
        font-size: 14px;
    }
    .snap-shot-img{
        max-height: 100%;
        max-width: 100%;
    }
    .open-image::after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
</style>