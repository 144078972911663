import { computed, ref } from "vue";
import store from "../store";

const user = ref(computed(()=>{
    return store.state.user
}))

const is_admin = ref(computed(()=>{
    return store.state.admin.is_user_admin
}))

export{
    user,
    is_admin
}