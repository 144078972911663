<template>
  <div v-if="unreaded_messages.length>0" class="unreaded-msgs-numbers">
    {{unreaded_messages.length}}
  </div>
</template>

<script>
import { onBeforeUnmount } from '@vue/runtime-core'
import {handle_unreaded_msgs} from './Scripts/scripts'
export default {
    props:['user_uid'],
    setup(props){
        
        const {session_unsub,unreaded_messages} = handle_unreaded_msgs(props.user_uid)

        onBeforeUnmount(()=>{
            if(session_unsub.value){
                session_unsub.value()
            }
        })

        return{
            unreaded_messages,
        }
    }
}
</script>

<style scoped>
     .unreaded-msgs-numbers{
        position: absolute;
        width: 20px;
        height: 20px;
        bottom: 30px;
        right: 0;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 10px;
        background: var(--danger);
    }
</style>