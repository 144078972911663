<template>
<div class="home">

</div>
</template>

<script>
import { onMounted } from '@vue/runtime-core'
import store from '../../store'
export default {
  name: 'Home',
  components: {
  },
  setup(){
    onMounted(() => {
      store.state.loading = false;
    })
    return{

    }
  }
}
</script>
<style>
.home{
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: 10px;
  display: flex;
  flex-direction: column;
  background: var(--grad_bg);  
}


</style>
