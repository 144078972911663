<template>
    <div class="sys-updater">
        <div class="model-header">
            <p>עדכון מערכת</p>
        </div>
        <button class="select-btn" @click="update_now">
            <p>עדכן עכשיו</p>
        </button>
    </div>
</template>

<script>
export default {
props:[],
emit:["update"],
setup(_, context){
    const update_now = async () => {
        context.emit("update")
    }
    return{
        update_now
    }
}
}
</script>

<style scoped>
.sys-updater{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.834);
}
.model-header{
    color:var(--light_green); 
    background: var(--dark_btn); 
    font-size:24px;
    max-width: 360px;
    border-radius: 5px;
    margin-bottom: 10px;
}
</style>