import { computed, ref } from "vue";
import { projectFirestore } from "../../../../Firebase/config";
import store from "../../../../store";
import {get_date_now} from '../../../../Scripts/dates'

const user = ref(computed(()=>{
    return store.state.user
}))


const handle_when_user_connect = async()=>{
    await update_user_presence_in_db('online')
}

const init_visibility = ()=>{
    document.addEventListener('visibilitychange',is_online, false);
}

//בודק אם המשתמש מחובר או מנותק
const is_online = async()=>{
    if(!document.hidden){
        //online
        await update_user_presence_in_db('online')
    }else{
        //offline
        await update_user_presence_in_db('offline')
    }
}

const update_user_presence_in_db = async(status)=>{
    if(status=='online' && user.value.uid){
        await projectFirestore.collection('Online_Users').doc(user.value.uid)
        .set({
            created_at: get_date_now(),
            user_uid: user.value.uid,
            user_name: user.value.name?user.value.name:'',
            phone: user.value.phone,
            email: user.value.email,
            photo_url:user.value.photo_url,
        })
    }
    if(status=='offline' && user.value.uid){
        await projectFirestore.collection('Online_Users').doc(user.value.uid).delete()
    }
}

const get_users_online_from_db = async() =>{
    const docs = await projectFirestore.collection('Online_Users').get()
    const res = docs.docs.map(doc=>doc.data())
    return res
}

export{
    handle_when_user_connect,
    init_visibility,
    get_users_online_from_db,
}