import { ref, watchEffect } from "vue"
import { projectFirestore } from "../Firebase/config"

import {user} from './Utils'

const get_users_by_organisation_id = async(organisation_id = user.value.organisation_id)=>{
    if(organisation_id){
        const docs = await projectFirestore.collection('Users').where('public_chat','==',true)
        .where('organisationId','==',organisation_id).get()
        const res = docs.docs.map(doc=>doc.data())
        return res
    }
    return []
}

// real time
const get_users_by_organisation_id_RT = (organisation_id = user.value.organisation_id)=>{
    
    const users = ref([])
    const users_unsub = ref(null)

    if(organisation_id){
        users_unsub.value = projectFirestore.collection('Users').where('public_chat','==',true)
        .where('organisationId','==',organisation_id).where('uid','!=',user.value.uid).onSnapshot(snapshot => {
            snapshot.docChanges().forEach((change) => {
               if (change.type === "added") {
                users.value.push(change.doc.data())
               }
               if (change.type === "modified") {
                const index = users.value.findIndex(user=>user.uid==change.doc.data().uid)
                if(index!=-1){
                    users.value[index]=change.doc.data()
                }
               }
               if (change.type === "removed") {
                const index = users.value.findIndex(user=>user.uid==change.doc.data().uid)
                if(index!=-1){
                    users.value.splice(index,1)
                }
               }
           })
       })
    }

    watchEffect((onInvalidate)=>{
        onInvalidate(()=>users_unsub.value())
    })
    return {users,users_unsub}

}


export{
    get_users_by_organisation_id,
    get_users_by_organisation_id_RT
}