<template>
  <div @click="handle_trigger_chat" class="trigger-public-chat slide-in-left">
    <div v-if="total_unreaded_msgs.length>0" class="unreaded-msgs-numbers">
        {{total_unreaded_msgs.length}}
    </div>
    <span class="material-symbols-rounded">sms</span>
  </div>
</template>

<script>
import store from '../../store'
import {total_unreaded_msgs} from './Scripts/scripts'
export default {
    setup(){
        
        const handle_trigger_chat = () => {
            store.state.client.show_public_chat = !store.state.client.show_public_chat
        }

        return{
            handle_trigger_chat,
            total_unreaded_msgs,
        }
    }
}
</script>

<style scoped>
    .trigger-public-chat{
        position: fixed;
        width: 60px;
        height: 60px;
        background: var(--light_green);
        left: 0;
        bottom: 100px;
        z-index: 100;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        cursor: pointer;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.075);
        color: #fff;
    }
    .trigger-public-chat span{
        font-size: 40px;
        color: white;
        text-shadow: 0 0 5px rgba(0, 0, 0, 0.273);
    }
    .trigger-public-chat .unreaded-msgs-numbers{
        position: absolute;
        width: 20px;
        height: 20px;
        top: -5px;
        right: -5px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 10px;
        background: var(--danger);
    }
</style>