
// return ISO date with UTC Z 
const get_date_now = ()=>{
    const date = new Date().toISOString()
    return date
}

// return the format and local date of the user 
const display_date_from_iso = (iso_date) => {
    const local_zone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const user_lang = navigator.languages && navigator.languages.length
    ? navigator.languages[navigator.languages.length-1]
    : navigator.language
    return new Date(iso_date).toLocaleDateString(user_lang, {timeZone:local_zone})
}

// return the format and local date and time of the user 
const display_date_and_time_from_iso = (iso_date) => {
    const local_zone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const user_lang = navigator.languages && navigator.languages.length
    ? navigator.languages[navigator.languages.length-1]
    : navigator.language

    return new Date(iso_date).toLocaleString(user_lang, {timeZone:local_zone})
}

// return the format and local time of the user 
const display_only_time_from_iso = (iso_date) => {
    const local_zone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const user_lang = navigator.languages && navigator.languages.length
    ? navigator.languages[navigator.languages.length-1]
    : navigator.language

    return new Date(iso_date).toLocaleTimeString(user_lang, {timeZone:local_zone})
}

// return date object with no time - Good for comparing
const get_object_date_with_no_time = (dateString)=>{
    const d=new Date(dateString)
    d.setHours(0,0,0,0)
    return d
}
// return date object with no time - Good for comparing
const get_object_date_with_time = (dateString)=>{
    const d=new Date(dateString)
    return d
}

// return date object with time when the data is timstamp from server 
const timestamp_to_date_with_time=(date)=>{
    let d
    if(date._second) d = new Date(date._seconds * 1000)
    if(date.second) d = new Date(date.seconds * 1000)
    return d
}

// return date object with no time when the data is timstamp from server 
const timestamp_to_date_no_time=(date)=>{
    let d
    if(date._second) d = new Date(date._seconds * 1000)
    if(date.second) d = new Date(date.seconds * 1000)
    d.setHours(0,0,0,0)
    return d
}

// Time difference between 2 Dates:
const time_diff_calc = (dateFuture, dateNow) => {
    let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

    // calculate days
    const days = Math.floor(diffInMilliSeconds / 86400);
    diffInMilliSeconds -= days * 86400;

    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;

    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;

    let difference = '';
    if (days > 0) {
      difference += (days === 1) ? `${days} day, ` : `${days} days, `;
    }

    difference += (hours === 0 || hours === 1) ? `${hours} hour, ` : `${hours} hours, `;

    difference += (minutes === 0 || hours === 1) ? `${minutes} minutes` : `${minutes} minutes`; 

    return [days, hours, minutes];
}

export{
    get_date_now,
    display_date_from_iso,
    display_date_and_time_from_iso,
    display_only_time_from_iso,
    get_object_date_with_no_time,
    get_object_date_with_time,
    timestamp_to_date_with_time,
    timestamp_to_date_no_time,
    time_diff_calc
}