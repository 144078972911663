<template>
  <div :data-isconnect="is_connect" class="user-connect-sign">

  </div>
</template>

<script>
import { onBeforeUnmount } from '@vue/runtime-core'
import {user_is_conecting_RT} from './Scripts/scripts'
export default {
    props:['user_uid'],
    setup(props){
        const {is_connect,user_unsub}  = user_is_conecting_RT(props.user_uid)

        onBeforeUnmount(()=>{
            if(user_unsub.value){
                user_unsub.value()
            }
        })
        return{is_connect}
    }
}
</script>

<style scoped>
    .user-connect-sign{
        position: absolute;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        top: 0;
        right: 0;        
    }
    [data-isconnect="true"]{
        background: var( --success);
    }
    [data-isconnect="false"]{
        background: var(--danger);
    }
</style>