<template>
    <div class="main-os">
        <div class="os-router">
            <router-view/>
        </div>
    </div>
</template>

<script>
import { computed, ref, onBeforeMount} from '@vue/runtime-core'
import store from '../../store'
import router from '../../router'
export default {
setup(){
    const user = ref(computed(() => {
        return store.state.user
    }))

     onBeforeMount(() => {
        if(!user.organization){
            router.push('/OS/noorg');
            store.state.loading = false;
        }
    })

    return{

    }
}
}
</script>

<style scoped>
.main-os{
    position: relative;
    width: 100%;
    height: 100%;
}
.os-router{
    width: 100%;
    max-width: 1200px;
    height: 100%;
    margin: auto;
    background: var(--grad_bg);
}
</style>