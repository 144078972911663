<template>
    <TriggerChat v-if="!is_show_public_chat && current_user"/>
    <div v-show="is_show_public_chat && current_user" ref="public_chat_box" class="public-chat-box slide-in-left">
        <div class="chat-header">
            <div class="close-btn" @click="handle_close_chat_window">
                <i class="material-symbols-rounded" style="text-shadow:none;">highlight_off</i>
            </div>
            <div ref="minimize_btn" class="minimize-btn" @click="handle_minimize_chat_window">
                 <i class="material-symbols-rounded" style="text-shadow:none;">expand_more</i>
            </div>
            <p>צ'אט</p>
        </div>
        <div class="chat-content">
                <component @selected_user="handle_selected_user" 
                :selected_user="selected_user"
                @back="selected_user=null; state='UsersList'"
                :is="state" :users="users" />
        </div>
    </div>
    
  <!-- just for mail -->
    <!-- <div v-if="current_user" class="form-email" id="form-email" v-show="false">
      <table
        style="width:80%; border-collapse: collapse; font-size: 1.1rem; margin: 1rem 0;"
      >
        <tr style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            תאריך וזמן
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ new Date().toLocaleDateString('he') }},{{new Date().toLocaleTimeString('he')}}
          </td>
        </tr>
        <tr>
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            שם המשתמש
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{current_user.name}}
          </td>
        </tr>
        
      </table>
    </div> -->
</template>

<script>
import { computed, onBeforeUnmount, ref } from '@vue/runtime-core'
import TriggerChat from './TriggerChat.vue'
import store from '../../store'
import UsersList from './UsersList.vue'
import ChatScreen from './ChatScreen.vue'
import {get_users_by_organisation_id_RT} from '../../Scripts/Organisation'

export default {
    components:{TriggerChat,UsersList,ChatScreen},
    setup(){
        const {users,users_unsub} = get_users_by_organisation_id_RT()
        const state = ref('UsersList')

        const selected_user = ref(null)

        const current_user = ref(computed(()=>{
            return store.state.user
        }))

        
        const public_chat_box = ref()
        const minimize_btn = ref()

        const is_show_public_chat = ref(computed(()=>{
            return store.state.client.show_public_chat
        }))
        
        const handle_selected_user = (user)=>{
            selected_user.value = user
            state.value = 'ChatScreen'
        }
        const handle_close_chat_window = () => {
            toggle_comp()
        }

        const handle_minimize_chat_window = ()=>{
            public_chat_box.value.classList.toggle('minimize-chat');
            minimize_btn.value.classList.toggle('rotate-icon')
        }

        const toggle_comp = () => {
            store.state.client.show_public_chat = !store.state.client.show_public_chat
        }

        onBeforeUnmount(()=>{
            if(users_unsub.value){
                users_unsub.value()
            }
        })
    
        return{
            handle_close_chat_window, 
            handle_minimize_chat_window,
            handle_selected_user,
            public_chat_box,
            minimize_btn,
            is_show_public_chat,
            current_user,
            selected_user,
            state,
            users,
        }
    }
}
</script>

<style scoped>
    .public-chat-box{
        position: fixed;
        bottom: 10px;
        left: 5px;
        width: 340px;
        max-width: calc(100% - 10px);
        height: 550px;
        max-height: calc(100% - 20px);
        background: #fff;
        border-radius: 10px;
        overflow: hidden;
        z-index: 1000;
        transition: height 0.2s;
    }
    .public-chat-box .chat-header{
        position: relative;
        width: 100%;
        height: 50px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--turkiz);
        color: white;
        font-size: 24px;
        text-shadow: 0 0 3px rgba(0, 0, 0, 0.76);
    }
    .public-chat-box .chat-msgs{
        position: relative;
        width: 100%;
        height: calc(100% - 130px);
        overflow:hidden;
        overflow-y: auto;
        padding: 3px;
    }
    .close-btn{
        position: absolute;
        top: 10px;
        left: 5px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--danger);
        cursor: pointer;
    }
    .close-btn:hover i{
        transform: scale(0.95);
    }
    .close-btn i {
        transition: 0.25s;
    }
    .minimize-btn{
        position: absolute;
        top: 10px;
        left: 50px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--turkiz);
        cursor: pointer;
        transition: transform 1s;
        user-select: none;
    }
    .minimize-btn.rotate-icon{
        transform: rotate(-180deg);
    }
    .public-chat-box.minimize-chat{
        height: 50px;
        width: 100px;
        background: unset;
        bottom: 100px;
    }
    .public-chat-box.minimize-chat .chat-header{
        justify-content: flex-end;
        font-size: 20px;
        padding: 0 5px;
    }
    .public-chat-box.minimize-chat .close-btn{
        display: none;
    }
    .public-chat-box.minimize-chat .minimize-btn{
        left: 5px;
    }
    .public-chat-box.minimize-chat > div:not(:first-child){
        display: none;
    }
    .chat-content{
        width: 100%;
        height: calc(100% - 50px);
    }
</style>