<template>
    <div class="loading-full">
        <img src="@/assets/Logo/Beezi_180.png" alt="">
        <div class="main-txt">
            <p>העמוד המבוקש לא נמצא!</p>
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { computed } from '@vue/runtime-core'
import store from '../store'
export default {
setup(){
    return{
    }
}
}
</script>

<style scoped>
.loading-full{
    position: fixed;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--grad_bg);
    background-image: url('../assets/Back/back.svg');
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1000000;
    direction: rtl;
}
.loading-full img{
    border-radius: 10px;
    background: white;
}

.main-txt{
    margin-top: 25px;
    width: 100%;
    text-align: center;
    font-size: 18px;
    color: white;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.438);
}

</style>