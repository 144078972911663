import firebase from "firebase/app";
import 'firebase/firestore';
import 'firebase/auth';
import { getAuth } from "firebase/auth";

import 'firebase/storage';
import 'firebase/functions';
import 'firebase/database';
import 'firebase/app-check';

const firebaseConfig = {
  apiKey: "AIzaSyDSs3_twzZaaoDFJoYwb6yGogfsW6xX2bc",
  authDomain: "beezi1.firebaseapp.com",
  projectId: "beezi1",
  storageBucket: "beezi1.appspot.com",
  messagingSenderId: "332734157469",
  appId: "1:332734157469:web:536093518c3ae3dafc7a74",
  measurementId: "G-DY2JN4R4CN"
};
// init firebase
var myApp = firebase.initializeApp(firebaseConfig);

// // init services 

const projectFirestore = myApp.firestore();
const projectAuth = myApp.auth();
const projectAuthVer = firebase.auth;
const projectStorage = myApp.storage();

const projectFunctions= myApp.functions('europe-west6');
// projectFunctions.useEmulator("localhost", 5001); 

const FireStore = firebase.firestore;

myApp.auth().useDeviceLanguage();

                                                               

export { projectFirestore,projectStorage, projectAuth, FireStore, projectFunctions, projectAuthVer}; 