import { projectFunctions } from "../../Firebase/config";

const set_user_display_name = async(displayName,user_uid)=>{
    try{
        await projectFunctions.httpsCallable('update_user_auth_display_name')({displayName,user_uid});
    }catch(err){
        throw new Error(err.message)
    }
}
const set_user_profile_image = async(image_url,user_uid)=>{
    try{
        await projectFunctions.httpsCallable('update_user_auth_image')({image_url,user_uid});
    }catch(err){
        throw new Error(err.message)
    }
}
const set_user_phone_number = async(phone,user_uid)=>{
    try{
        await projectFunctions.httpsCallable('update_user_auth_phone_number')({phone,user_uid});
    }catch(err){
        throw new Error(err.message)
    }
}
const set_user_email = async(email,user_uid)=>{
    try{
        await projectFunctions.httpsCallable('update_user_auth_email')({email,user_uid});
    }catch(err){
        throw new Error(err.message)
    }
}
const set_user_password = async(password,user_uid)=>{
    try{
        await projectFunctions.httpsCallable('update_user_auth_password')({password,user_uid});
    }catch(err){
        throw new Error(err.message)
    }
}
const set_user_disable = async(disabled,user_uid)=>{
    try{
        await projectFunctions.httpsCallable('update_user_auth_password')({disabled,user_uid});
    }catch(err){
        throw new Error(err.message)
    }
}

export{
    set_user_display_name,
    set_user_profile_image,
    set_user_phone_number,
    set_user_password,
    set_user_disable,
    set_user_email,
}





