import { ref, watchEffect } from "vue";
import { projectFirestore } from "../../../Firebase/config";
import {user} from '../../../Scripts/Utils'
import {get_date_now} from '../../../Scripts/dates'

const total_unreaded_msgs = ref([])

const handle_total_unreaded_msgs = (type,msg_id)=>{
    let index
    switch (type) {
        case 'added':
            index = total_unreaded_msgs.value.findIndex(id=>id==msg_id)
            if(index==-1){
                total_unreaded_msgs.value.push(msg_id)
            }
            break;
        case 'removed':
            index = total_unreaded_msgs.value.findIndex(id=>id==msg_id)
            if(index!=-1){
                
                total_unreaded_msgs.value.splice(index,1)
            }
            break;   
    }
}

const user_is_conecting_RT = (user_uid)=>{

    const is_connect = ref(false)
    const user_unsub = ref(null)

    user_unsub.value = projectFirestore.collection('Online_Users').where('user_uid','==',user_uid).onSnapshot(snapshot => {
        snapshot.docChanges().forEach((change) => {
           if (change.type === "added") {
            is_connect.value = true
           }
           if (change.type === "removed") {
            is_connect.value = false
           }
       })
   })

    watchEffect((onInvalidate)=>{
        onInvalidate(()=>user_unsub.value())
    })


   return {
    is_connect,
    user_unsub,
   }
}

const real_time_chat_messages = (partner_uid)=>{
    const primary_user = ref(user.value.uid)
    const second_user = ref(partner_uid)
    const session_unsub = ref(null)
    const messages = ref([])

    const docs = projectFirestore.collection('Users').doc(partner_uid)
    .collection('PublicChat').doc(user.value.uid).collection('Msgs')
    docs.get().then(docs=>{
        if(!docs.empty){
            primary_user.value = partner_uid
            second_user.value = user.value.uid
        }
        session_unsub.value = projectFirestore.collection('Users').doc(primary_user.value)
        .collection('PublicChat').doc(second_user.value).collection('Msgs').onSnapshot(snapshot => {
            snapshot.docChanges().forEach((change) => {
                if (change.type === "added") {
                    messages.value.push(change.doc.data())
                    if(change.doc.data().user_uid!=user.value.uid && !change.doc.data().read){
                        handle_read_msg(primary_user.value,second_user.value,change.doc.data().msgId)
                    }
                }
                if (change.type === "modified") {
                    const index = messages.value.findIndex(message=>message.msgId==change.doc.data().msgId)
                    if(index!=-1){
                        messages.value[index]=change.doc.data()
                    }
                }
                if (change.type === "removed") {
                    const index = messages.value.findIndex(message=>message.msgId==change.doc.data().msgId)
                    if(index!=-1){
                        messages.value.splice(index,1)
                    }
                }
            })
        })
    })

    watchEffect((onInvalidate)=>{
        onInvalidate(()=>session_unsub.value())
    })

    return{
        primary_user,
        second_user,
        session_unsub,
        messages,
    }
    
}

const send_msg = async(primary_user,second_user,msg_text) => {
    if(msg_text){
        const doc = projectFirestore.collection('Users').doc(primary_user)
        .collection('PublicChat').doc(second_user).collection('Msgs').doc()
        await doc.set({
           createdAt:get_date_now(),
           msgId:doc.id,
           name:user.value.name,
           text_msg:msg_text,
           read:false,
           user_uid:user.value.uid
        })
    }
}

const handle_read_msg = async(primary_user,second_user,msg_uid)=>{
    await projectFirestore.collection('Users').doc(primary_user)
    .collection('PublicChat').doc(second_user).collection('Msgs').doc(msg_uid)
    .set({
        read:true
    },{merge:true})
    handle_total_unreaded_msgs('removed',msg_uid)
}

const handle_unreaded_msgs = (partner_uid)=>{
    const primary_user = ref(user.value.uid)
    const second_user = ref(partner_uid)
    const session_unsub = ref(null)
    const unreaded_messages = ref([])
    
    const docs = projectFirestore.collection('Users').doc(partner_uid)
    .collection('PublicChat').doc(user.value.uid).collection('Msgs')
    docs.get().then(docs=>{
        if(!docs.empty){
            primary_user.value = partner_uid
            second_user.value = user.value.uid
        }
        session_unsub.value = projectFirestore.collection('Users').doc(primary_user.value)
        .collection('PublicChat').doc(second_user.value).collection('Msgs').where('user_uid','!=',user.value.uid)
        .onSnapshot(snapshot => {
            snapshot.docChanges().forEach((change) => {
                if (change.type === "added") {
                    if(!change.doc.data().read){
                        unreaded_messages.value.push(change.doc.data().msgId)
                        handle_total_unreaded_msgs('added',change.doc.data().msgId)
                    }
                }
                // if (change.type === "modified") {
                //     console.log('modify');
                //     if(change.doc.data().read){
                //         const index = unreaded_messages.value.findIndex(id=>id==change.doc.data().msgId)
                //         if(index!=-1){
                //             unreaded_messages.value.splice(index,1)
                //             handle_total_unreaded_msgs('modified',change.doc.data().msgId)
                //         }
                //     }
                // }
                // if (change.type === "removed") {
                //     const index = unreaded_messages.value.findIndex(id=>id==change.doc.data().msgId)
                //     if(index!=-1){
                //         unreaded_messages.value.splice(index,1)
                //         handle_total_unreaded_msgs('removed',change.doc.data().msgId)
                //     }
                // }
            })
        })

    })

    watchEffect((onInvalidate)=>{
        onInvalidate(()=>session_unsub.value())
    })

    return{
        unreaded_messages,
        session_unsub
    }

}



export{
    user_is_conecting_RT,
    real_time_chat_messages,
    send_msg,
    handle_read_msg,
    handle_unreaded_msgs,
    total_unreaded_msgs,
}