import { projectFirestore, projectFunctions } from "../Firebase/config";
import {set_user_profile_image} from '../DebugTools/Scripts/User_comands'
import store from "../store";


const init_profile = async(user) => {
    try{
        //Check if the user is Admin
        user.getIdTokenResult().then(isTokenResult=>{
            user.admin = isTokenResult.claims.admin
            store.state.admin.is_user_admin = isTokenResult.claims.admin
        })
        const user_doc = await projectFirestore.collection('Users').doc(user.uid).get()
        if(user_doc.exists){
            store.state.user.organisation_id = user_doc.data().organisationId
            store.state.user.public_chat = user_doc.data().public_chat
            return false
        }else{
            await projectFunctions.httpsCallable('create_new_user')({
                user:{
                    displayName:user.displayName,
                    phoneNumber:user.phoneNumber,
                    uid:user.uid,
                    photoURL:user.photoURL,
                    emailVerified:user.emailVerified,
                    organisationId:'',
                    public_chat: true
                },
            });
            return true
        }
    }catch(error){
        throw new Error(error)
    }
}

const init_permission = async(user) => {
    try{
        await projectFunctions.httpsCallable('init_permission')({user_uid:user.uid})
    }catch(error){
        throw new Error(error)
    }
}

const set_profile_image = async(image_url,user_uid) => {
    try{
        await set_user_profile_image(image_url,user_uid)
    }catch(error){
        throw new Error(err.message)
    }
}

export {
    init_profile,
    init_permission,
    set_profile_image
}