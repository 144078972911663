/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      Notification.requestPermission(function(status){
        console.log(status);
      });
    },
    registered () {
    },
    cached () {
    },
    updatefound () {
    },
    updated (registration) {
      document.dispatchEvent(new CustomEvent('serviceWorkerUpdateEvent', { detail: registration }));
    },
    offline () {
    },
    error (error) {
    }
  })
}
