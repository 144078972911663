<template>
  <el-config-provider :locale="locale">
    <SysUpdateWin v-if="show_update_alert" @update="update_system"/>
    <vue-notification-list position="top-left" style="z-index:1000; direction:rtl;"></vue-notification-list>
    <Loading v-if="loading"/>
    <UserQuick v-if="!loading"/>
    <DebugTools />

    <ChatSupport v-if="!is_admin && user" />
    <AdminChatSupport v-if="is_admin && user" />
    <PublicChat v-if="user && user.organisation_id" />

    <div id="main-router" class="main-router">
      <router-view/>
    </div>
  </el-config-provider>
</template>
<script>
import { computed, onMounted, ref } from '@vue/runtime-core';
import { defineAsyncComponent } from '@vue/runtime-core';
import store from './store';
import Loading from './views/Loading.vue';
import locale from 'element-plus/lib/locale/lang/he';
import SysUpdateWin from './components/System/Update/SysUpdateWin.vue'
import DebugTools from './DebugTools/Main.vue'
import {is_admin,user} from './Scripts/Utils'
import PublicChat from './components/PublicChat/PublicChat.vue'


export default {
  components:{
    UserQuick : defineAsyncComponent(() => import('./components/UserQuick/Main.vue')),
    ChatSupport: defineAsyncComponent(() => import('./components/ChatSupport/ChatSupport.vue')),
    AdminChatSupport : defineAsyncComponent(() => import('./AdminTools/Models/ChatSupport/ChatRoom.vue')),
    // PublicChat : defineAsyncComponent(() => import('./components/PublicChat/PublicChat.vue')),
    Loading, SysUpdateWin, DebugTools,PublicChat
  },
  setup() {
    const loading = ref(computed(() => {
      return store.state.loading
    }))
    //------------------- init!! web --------------------//
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    
  //------------------- Service Worker Handle!--------------------//
    const show_update_alert = ref(false);
    let sw_detailes = null;
    let refreshing = false;

    document.addEventListener('serviceWorkerUpdateEvent', (e) => {
      sw_detailes = e.detail;
      show_update_alert.value = true;
    }, { once: true });

    navigator.serviceWorker.addEventListener( 'controllerchange', () => {
      if (refreshing) return;
      refreshing = true;
      window.location.reload();
    });

    const update_system = () => {
      console.log('Updating Sys.');
      show_update_alert.value = false;
      if(sw_detailes  || sw_detailes.waiting){
        sw_detailes.waiting.postMessage({type:'SKIP_WAITING'});
      }
    }
    //----------------------------------------------------------//

    onMounted(() => {
      window.addEventListener('resize', (e) => {
        if(document.activeElement.type && document.activeElement.type === 'text'){
          // console.log("Test input, no resize.")
        }
        else{
          let vh = window.innerHeight * 0.01;
          document.documentElement.style.setProperty('--vh', `${vh}px`);
        }
      });
    })

    return{
      loading, locale, show_update_alert, update_system,
      is_admin,user
    }
  },
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');
@import './CSS/Cards.css';
@import './CSS/Animations.css';
@import './CSS/ScrollBars.css';
@import './CSS/Headers.css';
@import './CSS/Forms.css';
@import './CSS/Btns.css';

:root{
  --dark_bg: #262626;
  --ca_bg: #fc6867;
  --atten_bg: linear-gradient(45deg,#fc6867,#fd9265);
  --atten_bg2: linear-gradient(45deg,#1d899c,rgb(24, 95, 112));
  --grad_bg: linear-gradient(45deg,#126782,#023047);
  --grad_bg2: linear-gradient(45deg,#057294,#095283);
  --grad_success: linear-gradient(45deg,#28c76f,#18864a);
  --grad_success2: linear-gradient(45deg,#18864a,#28c76f);
  --turkiz: #126782;
  --turkiz_dark:#023047;
  --dark_bg_turkiz: #011c29;
  --dark_btn: #283046;
  --txt_grey: #37474f;
  --gold:#ebb81f;
  --yellow: #ffb703;
  --orange: #fd9e02;
  --blue: rgb(58, 178, 248);
  --light_pink:#e73c7e;
  --light_orange:#ee7752;
  --light_green:#23d5ab;
  --danger: #ea5455;
  --warning: #f5953b;
  --success: #28c76f;
  --light: #f6f6f6;
}
*{
  margin: 0;
  padding: 0;
  font-family: 'Rubik', sans-serif;  
  box-sizing: border-box;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  font-weight: 400;
}
.main-router{
  position: relative;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  background: var(--grad_bg);
  background-image: url('./assets/Back/Back2.svg');
  direction: rtl;
  overflow-x: hidden;
}
</style>
