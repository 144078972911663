<template>
  <div class="users-list">
    <div class="users-list-header">
        <h2>עמיתים בארגון</h2>
        <h4>
            סה"כ  {{users.length}} עמיתים
        </h4>
    </div>
    <div v-if="users.length>0" class="users-list-content">
        <template v-for="user in users" :key="user.uid">
            <div @click="$emit('selected_user',user)" class="user-waiting">
                <div class="user-image">
                    <UserConnectSign :user_uid="user.uid" />
                    <img v-if="user.photoURL" :src="user.photoURL">
                    <img v-else src="https://firebasestorage.googleapis.com/v0/b/beezi1.appspot.com/o/Icons%2Fprofile_img.jpg?alt=media&token=fc045fd3-39aa-45b3-b74c-7f927d80685b">
                </div>
                <div class="user-wiating-details">
                    <h5>{{user.displayName}}</h5>
                </div>
                 <UnreadedMsgsNumbers :user_uid="user.uid"/>
            </div>
        </template>
    </div>
    <div v-if="users.length==0" class="no-users">
        <img src="https://firebasestorage.googleapis.com/v0/b/beezi1.appspot.com/o/Icons%2Ftechnical-support.png?alt=media&token=0585ee2b-7555-4b04-adb5-63b913ad5042">
    </div>
  </div>
</template>

<script>
import UserConnectSign from './UserConnectSign.vue'
import UnreadedMsgsNumbers from './UnreadedMsgsNumbers.vue'
export default {
    props:['users'],
    components:{UserConnectSign,UnreadedMsgsNumbers},
    setup(){

        return{}
    }
}
</script>

<style scoped>
    .users-list{
        width: 100%;
        height: 100%;
    }
    .users-list-header{
        width: 100%;
        height: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .users-list-content{
        width: 100%;
        height: calc(100% - 50px);
        overflow: hidden;
        overflow-y: auto;
        display: grid;
        grid-template-columns: repeat(3,calc(100% / 3));
        grid-auto-rows: 100px;
        grid-gap: 2px;
    }
    .no-users{
        width: 100%;
        height: calc(100% - 50px);
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .no-users img{
        max-width: 70%;
        max-height: 70%;
    }
    .user-waiting{
        position: relative;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }
    .user-image{
        position: relative;
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .user-image img{
        max-width: 100%;
        max-height: 100%;
        border-radius: 50%;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.26);
        transition: transform 0.2s ease-in;
    }
    .user-image img:hover{
        transform: scale(1.05);
    }
    .user-wiating-details{
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
</style>