import { createStore } from 'vuex'

export default createStore({
  state: {
    user: null,
    loading: false,
    loading_text: "טוען...",
    temp_obj: null,
    admin:{
      is_user_admin:null,
      show_support_chat:false
    },
    client:{
      show_public_chat:false,
      show_support_chat:false
    }
  },
  mutations: {
  },
  actions: {
    
  },
  modules: {
  }
})
